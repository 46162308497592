import React, { ReactNode, useContext, useState } from 'react';
const Slider = React.lazy(() => import('react-slick') as Promise<{ default: any }>);

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icons } from 'src/types/icons';
import { Button, IconSvg } from '../common';
import { AuthContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';

interface Props {
  plans: {
    id: string;
    title: string;
    description1: string;
    description2: string;
    description3: string;
    description4: string;
    currentPlan: any;
    usd: number;
    currency: string;
    currencyId: string;
    savePer: string;
    button: string;
    cancelPlan: any;
    modalDescriptionText: string;
    modalTitle: string;
  }[];
  subscription: any;
  createSubscription: (id: string) => void;
  setSelectedPlan: (plan: any) => void;
  subscriptionModalHandler: (id: string) => void;
  resumeSubscriptionModalHandler: (id: string) => void;
  subscriptionConfirmationModalHandler: (id: string) => void;
  handleButtonClick: () => void;
}

export function ProfilePlansMobile({
  plans,
  subscription,
  createSubscription,
  setSelectedPlan,
  subscriptionModalHandler,
  resumeSubscriptionModalHandler,
  subscriptionConfirmationModalHandler,
  handleButtonClick,
}: Props) {
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);
  const [sliderRef, setSliderRef] = useState<any | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const settingsMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: false,
    centerHeight: '227px',
    centerWidth: 'w-full',
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };

  return (
    <>
      <div className="relative">
        <div className="slick-slider-container-plans-mobile mt-5 relative">
          <React.Suspense fallback={<div>Loading...</div>}>
            <Slider {...settingsMobileScreen} ref={(slider: any) => setSliderRef(slider)}>
              {plans.map((plan, i) => (
                <div
                  className={`${'border border-[#C56B48] rounded-[5px]'} flex flex-col justify-between  w-full relative mt-[22px]`}
                  key={plan.id}
                >
                  <div>
                    <div
                      className={`flex justify-center items-center ${
                        i == 2 ? '!bg-[#4A4A4A] border-white border-b' : 'bg-[#C56B48]'
                      }`}
                    >
                      <p className="uppercase sm:py-[17px] py-3 text-[#EFE8DF] text-2xl font-medium font-inter leading-[28.8px]">
                        {plan.title}
                      </p>
                    </div>
                    <div className="sm:pl-5 pl-3 sm:pr-[17px] pr-3 flex flex-col h-[231px]">
                      <div className="flex items-start gap-4 sm:pt-[30px] pt-4">
                        <IconSvg icon={'oDotSmallOrange'} className="w-[22px] h-[22px]" />
                        <p
                          className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          {plan.description1}
                        </p>
                      </div>
                      <div className="flex items-start gap-2.5 pt-5">
                        <IconSvg icon={'oDotSmallOrange'} />
                        <p
                          className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          {plan.description2}
                        </p>
                      </div>
                      <div className="flex items-start gap-2.5 pt-5">
                        <IconSvg icon={'oDotSmallOrange'} />
                        <p
                          className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          {plan.description3}
                        </p>
                      </div>
                      <div className="flex items-start gap-2.5 pt-5">
                        <IconSvg icon={i == 2 ? 'oDotWhiteSmall' : 'oDotSmallOrange'} />
                        <p
                          className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          {plan.description4}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center p-3 mt-4">
                    <div className="flex items-baseline gap-2 lg:mt-0 sm:mt-5 mt-0">
                      <h1 className="pb-5 text-[#0F0F0F]  text-[28px] font-inter font-light leading-[33.6px]">
                        {plan.usd} {plan.currency}
                      </h1>
                      {plan.savePer && (
                        <p className="text-[#EFE8DF]  text-base font-inter font-bold leading-[19.2px] bg-[#4A4A4A] px-2.5 py-1.5 rounded-[10px]">{`(SAVE ${plan.savePer}%)`}</p>
                      )}
                    </div>

                    {!subscription ? (
                      <Button
                        size="small"
                        color="primary"
                        label={
                          <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                            {plan.button}
                          </p>
                        }
                        className="px-16"
                        onClick={() => {
                          createSubscription(plan.id);
                          setSelectedPlan(plan);
                        }}
                      />
                    ) : null}

                    {subscription && !subscription.cancel_at_period_end && !plan.cancelPlan ? (
                      <Button
                        size="small"
                        color="primary"
                        label={
                          <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                            {plan.button}
                          </p>
                        }
                        className="px-16"
                        onClick={() => {
                          subscriptionModalHandler(plan.id);
                          setSelectedPlan(plan);
                        }}
                      />
                    ) : null}

                    {subscription && subscription.cancel_at_period_end && !plan.cancelPlan ? (
                      <Button
                        size="small"
                        color="primary"
                        isDisabled={true}
                        label={
                          <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                            {plan.button}
                          </p>
                        }
                        className="px-16"
                        onClick={() => {
                          console.log('');
                          // subscriptionModalHandler(plan.id);
                          // setSelectedPlan(plan);
                        }}
                      />
                    ) : null}

                    {plan.cancelPlan ? (
                      <Button
                        size="small"
                        color="onlyBorder"
                        label={
                          <p className="uppercase font-medium font-inter text-lg leading-[21.6px]">
                            {plan.button}
                          </p>
                        }
                        className="w-[209px]"
                        onClick={() => console.log('')}
                      />
                    ) : null}

                    {plan.cancelPlan ? (
                      subscription?.cancel_at_period_end ? (
                        <p
                          className="uppercase font-medium text-sm leading-4 font-inter text-green-600 p-3 cursor-pointer"
                          onClick={() => resumeSubscriptionModalHandler(plan.id)}
                        >
                          resume plan
                        </p>
                      ) : (
                        <p
                          className="uppercase font-medium text-sm leading-4 font-inter text-[#9B2626] p-3 cursor-pointer"
                          onClick={() => subscriptionConfirmationModalHandler(plan.id)}
                        >
                          cancel plan
                        </p>
                      )
                    ) : (
                      <p className="uppercase font-medium text-sm leading-4 font-inter text-[#9B2626] p-3 cursor-pointer">
                        &nbsp;
                      </p>
                    )}
                  </div>
                  {i == 1 && (
                    <div className="absolute -top-5 right-4 flex items-center gap-2 bg-[#9B2626] py-2 px-3 rounded-[5px]">
                      <IconSvg icon="starCream" />
                      <p className="font-light font-inter text-sm text-[#EFE8DF] uppercase">
                        BEST VALUE{' '}
                      </p>
                    </div>
                  )}
                </div>
              ))}
              <div
                className={`${'border border-[#4A4A4A] bg-[#4A4A4A] rounded-[5px]'} flex flex-col justify-between w-full mt-[22px]`}
              >
                <div>
                  <div
                    className={`flex justify-center items-center ${'!bg-[#4A4A4A] border-white border-b'}`}
                  >
                    <p className="uppercase sm:py-[17px] py-3 text-[#EFE8DF] text-2xl font-medium font-inter leading-[28.8px]">
                      Enterprise
                    </p>
                  </div>
                  <div className="sm:pl-5 pl-3 sm:pr-[17px] pr-3 sm:pt-[30px] pt-4 flex flex-col justify-between  lg:h-[298px] sm:h-[319px] h-[280px] ">
                    <div>
                      <div className="flex items-start gap-2.5">
                        <IconSvg icon={'oDotSmallOrange'} />
                        <p
                          className={`text-[#EFE8DF]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          Include XPLORIUS in your employee benefits scheme
                        </p>
                      </div>
                      <div className="flex items-start gap-2.5 pt-5">
                        <IconSvg icon={'oDotSmallOrange'} />
                        <p
                          className={`text-[#EFE8DF]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          Unlock unlimited discounted business trips
                        </p>
                      </div>
                      <div className="flex items-start gap-2.5 pt-5">
                        <IconSvg icon={'oDotSmallOrange'} />
                        <p
                          className={`text-[#EFE8DF]  text-base font-inter font-light leading-[19.2px]`}
                        >
                          Receive dedicated B2B TERMS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3 flex flex-col items-center justify-end h-full">
                  <Button
                    size="small"
                    color="orange"
                    label={
                      <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                        Talk to sales
                      </p>
                    }
                    className="w-[221px] px-[16px]"
                    startIcon
                    icon={<IconSvg icon="headPhone" className="w-[29px] h-[29px]" />}
                    onClick={handleButtonClick}
                  />
                </div>
                <p className="uppercase font-medium text-sm leading-4 font-inter text-[#9B2626] p-3 cursor-pointer">
                  &nbsp;
                </p>
              </div>
            </Slider>
          </React.Suspense>
        </div>
        <div className={`flex items-center justify-between absolute -bottom-8 w-full`}>
          {settingsMobileScreen.prevArrow}
          {settingsMobileScreen.nextArrow}
        </div>
      </div>
    </>
  );
}
