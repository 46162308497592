import React, { ReactNode, useContext, useEffect, useState } from 'react';
const Slider = React.lazy(() => import('react-slick') as Promise<{ default: any }>);

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icons } from 'src/types/icons';
import { Button, IconSvg } from '../common';
import { AuthContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';
import { useHttp } from 'src/hooks';
import { SelectComponent } from '../common/form';
import { LoadingSpinner } from '../common/ui/loading-spinner/LoadingSpinner';
interface Currency {
  id: string;
  name: string;
  code: string;
  symbol: string;
  isActive: boolean;
}

interface PlanDetail {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}

interface Plan {
  id: string;
  name: string;
  amount: number;
  priceId: string;
  createdAt: string;
  updatedAt: string;
  details: PlanDetail[];
  currency: Currency;
}

export function PlansMobile() {
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);
  const [sliderRef, setSliderRef] = useState<any | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { sendRequest, isLoading: isLoading, error } = useHttp();

  const [currencyOptions, setCurrencyOptions] = useState<{ label: string; value: string }[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<{ label: string; value: string }>();
  const [prices, setPrices] = useState<any>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const getPrices = () => {
    if (defaultCurrency?.value) {
      sendRequest(
        {
          url: `payment/prices?currencyId=${defaultCurrency?.value}`,
          method: 'GET',
        },
        (data: any) => {
          setPrices(data);
        },
      );
    }
  };
  const getCurrencies = () => {
    sendRequest(
      {
        url: 'currency',
        method: 'GET',
      },
      (data: any) => {
        setCurrencies(data);

        const options = data?.map((currency: Currency) => ({
          label: `${currency.code}`,
          value: currency.id,
        }));
        setCurrencyOptions(options);

        if (options.length > 0) {
          setDefaultCurrency(options[0]);
        }
      },
    );
  };
  useEffect(() => {
    getCurrencies();
  }, []);
  useEffect(() => {
    getPrices();
  }, [defaultCurrency?.value]);

  const handleCurrencyChange = (input: { name: string; value: string }) => {
    const selectedOption = currencyOptions.find(option => option.value === input.value);
    if (selectedOption) {
      setDefaultCurrency(selectedOption);
    }
  };

  const membershipPlans = [
    {
      title: 'BIANNUAL',
      features: [
        'Unrestricted access for 6 months to our premium hotel booking platform, 24/7',
        'No cap on the number of hotel bookings',
        'Billed every 6 months',
        'If you decide within the first 24 hours of signing up that we are not the right fit for you, we will offer you a full refund (unless a booking has been made)',
      ],
      // price: '99 USD',
      price: (prices[0]?.amount || '') + ' ' + (prices[0]?.currency?.code || ''),

      buttonText: 'SIGN UP',
      colorScheme: '#4A4A4A',
      isHighlighted: false,
    },
    {
      title: 'YEARLY',
      features: [
        'Unrestricted access for a whole year to our premium hotel booking platform, 24/7',
        'No cap on the number of hotel bookings',
        'Billed once a year',
        'If you decide within the first 24 hours of signing up that we are not the right fit for you, we will offer you a full refund (unless a booking has been made)',
      ],
      // price: '150 USD',
      price: (prices[1]?.amount || '') + ' ' + (prices[1]?.currency?.code || ''),

      buttonText: 'SIGN UP',
      colorScheme: '#C56B48',
      isHighlighted: false,
    },
    {
      title: 'ENTERPRISE',
      features: [
        'Include XPLORIUS in your employee benefits scheme',
        'Unlock unlimited discounted business trips',
        'Receive dedicated B2B terms',
      ],
      price: '.',
      buttonText: 'TALK TO SALES',
      colorScheme: '#C56B48',
      isHighlighted: true,
    },
  ];

  const settingsMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: false,
    centerHeight: '227px',
    centerWidth: 'w-full',
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };

  const handleButtonClick = (buttonText: string, planTitle: string) => {
    if (buttonText === 'SIGN UP') {
      const matchingPlan = prices.find((plan: any) => plan.name === planTitle);

      signupHandlerWithObject(matchingPlan);
    } else if (buttonText === 'TALK TO SALES') {
      window.open(
        'https://api.whatsapp.com/message/6GFBB37QHD6UN1?autoload=1&app_absent=0',
        '_blank',
      );
    }
  };

  const signupHandlerWithObject = (plan?: Plan) => {
    if (accessToken) {
      navigate('/profile');
    } else if (defaultCurrency && plan) {
      const { priceId, amount: planAmount, currency, name: planTitle } = plan;

      const currencyId = defaultCurrency.value; // Assuming `defaultCurrency` holds the ID
      navigate(
        `/sign-up?step=2&planAmount=${planAmount}&currency=${currency.code}&currencyId=${currencyId}&planId=${priceId}&planTitle=${planTitle}`,
      );
    } else {
      navigate('/sign-up');
    }
  };

  return (
    <>
      <div className=" sm:pt-[50px] pt-[32px] px-[10px]">
        <h2 className="uppercase  sm:text-3xl text-[24px] font-medium font-inter text-[#0F0F0F] leading-[28.32px]">
          Membership & Pricing
        </h2>
        <div className="flex items-center justify-between pt-5">
          <p className="uppercase leading-[19.2px] text-base font-light text-[#4A4A4A]">
            sign up & start saving <br className="sm:hidden block" />{' '}
            <span className="font-bold font-inter">up to 70%</span> per booking
          </p>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <SelectComponent
              id="currency-select"
              name="currency"
              options={currencyOptions}
              onChange={handleCurrencyChange}
              placeholder="Select a currency"
              value={defaultCurrency}
              wrapperStyles="flex items-center"
            />
          )}
        </div>
      </div>
      <div className="relative">
        <div className="slick-slider-container-plans-mobile sm:mt-[32px] mt-5 relative px-[10.5px]">
          <React.Suspense fallback={<div>Loading...</div>}>
            <Slider {...settingsMobileScreen} ref={(slider: any) => setSliderRef(slider)}>
              {membershipPlans.map((plan, i) => (
                <div
                  className={`border rounded-lg relative mt-[22px] ${
                    plan.isHighlighted ? 'border-[#4A4A4A]  bg-[#4A4A4A]' : 'border-[#C56B48]'
                  }`}
                  key={i}
                >
                  {i == 1 && (
                    <div className="absolute -top-5 right-4 flex items-center gap-2 bg-[#9B2626] py-2 px-3 rounded-[5px]">
                      <IconSvg icon="starCream" />
                      <p className="font-light font-inter text-sm text-[#EFE8DF] uppercase">
                        BEST VALUE{' '}
                      </p>
                    </div>
                  )}
                  <div
                    className={`flex justify-center rounded-t-lg items-center ${
                      plan.isHighlighted ? '!bg-[#4A4A4A] border-white border-b' : 'bg-[#C56B48]'
                    }`}
                  >
                    <p className="uppercase sm:py-[17px] py-3 text-[24px] font-medium font-inter text-white">
                      {plan.title}
                    </p>
                  </div>
                  <div className="sm:pl-5 pl-3 sm:pr-[17px] pr-3 sm:pt-[30px] pt-4">
                    <div className="h-[231px]">
                      {plan.features.map((feature, j) => (
                        <div className="flex items-start gap-2.5 pt-5" key={j}>
                          <IconSvg
                            icon={plan.isHighlighted ? 'oDotWhiteSmall' : 'oDotSmallOrange'}
                          />
                          <p
                            className={`${
                              plan.isHighlighted ? 'text-[#EFE8DF]' : ''
                            } sm:text-base text-base text-[#4A4A4A]`}
                          >
                            {feature}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-5 justify-center items-center sm:mt-[60px] mt-[90px] pb-[17px]">
                      <h1 className="text-[24px] leading-[28.8px] text-[#0F0F0F]">
                        <>
                          {i === 1 ? (
                            <>
                              {plan.price}
                              <span className="bg-[#4A4A4A] rounded-xl px-[10px] py-[7px] ml-2 text-[#EFE8DF] font-inter font-bold text-base leading-[19.2px]">
                                (SAVE 25%)
                              </span>
                            </>
                          ) : (
                            <span className={i === 2 ? 'text-[#4A4A4A]' : ''}>{plan.price}</span>
                          )}
                        </>
                      </h1>
                      <Button
                        color={i === membershipPlans.length - 1 ? 'orange' : 'yellow'}
                        size="medium"
                        label={
                          <p className="font-inter text-[19px] font-medium">{plan.buttonText}</p>
                        }
                        className={
                          plan.buttonText === 'TALK TO SALES'
                            ? 'h-[41px] w-[221px] px-[16px]'
                            : 'h-[41px] w-[166px]'
                        }
                        startIcon={plan.buttonText === 'TALK TO SALES'}
                        icon={
                          plan.buttonText === 'TALK TO SALES' ? (
                            <IconSvg icon="headPhone" className="w-[29px] h-[29px]" />
                          ) : undefined
                        }
                        onClick={() => handleButtonClick(plan.buttonText, plan.title)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </React.Suspense>
        </div>
        <div className={` flex items-center justify-between absolute -bottom-8 px-[11px]  w-full`}>
          {settingsMobileScreen.prevArrow}
          {settingsMobileScreen.nextArrow}
        </div>
      </div>
    </>
  );
}
