import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconSvg, Modal } from 'src/components/common';
import { useHttp } from 'src/hooks';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  isOpen: boolean;
  img: string;
  onCancel: () => void;
}

export function XploriusMailChimpModal({ isOpen, onCancel, img }: Props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const { sendRequest, isLoading, error } = useHttp();

  const handleReceiveNow = () => {
    if (!email) {
      toast.error('Email is required');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    sendRequest(
      {
        url: 'mailchimp/courchevel-subscribe',
        method: 'POST',
        payload: { email, firstName:firstname },
      },
      (response: any) => {
        toast.success('You have successfully subscribed to Courchevel 1850 guide!');
        onCancel();
        setEmail('');
        setFirstname('');
      }
    );
  };

  return (
    <Modal
      isStatic={window.innerWidth < 768 ? true : false}
      isOpen={isOpen}
      close={onCancel}
      loading={false}
      modalWidth="lg:w-[778px] sm:w-[600px] w-full !p-0 !m-0"
      modalBgClassName="!bg-black !opacity-[0.7] !backdrop-blur-[3px]"
    >
      <>
        <div className="h-full">
          <div className="flex flex-col lg:flex-row items-center lg:items-start gap-4 lg:gap-2 w-full h-full">
            {/* Image Section */}
            <div className="relative w-full lg:w-1/2 h-full flex items-center lg:items-start justify-center lg:justify-end">
              <img
                src={img}
                className="w-full lg:w-auto max-h-[300px] lg:max-h-full object-cover"
                loading="lazy"
              />
            </div>

            {/* Content Section */}
            <div className="flex flex-col justify-center items-center lg:items-start w-full lg:w-1/2 px-4 lg:py-0 py-2 lg:px-10 lg:mt-40">
              <div className="absolute right-4 top-4 lg:right-3 lg:top-3 bg-[#efe8df] p-2 rounded-full">
                <IconSvg icon="cross" className="cursor-pointer w-4" onClick={onCancel} />
              </div>
              <p className="font-bold text-lg lg:text-2xl leading-[20px] lg:leading-[29.05px] font-inter text-[#0F0F0F] text-center lg:text-left">
                Where do we send you the ultimate Courchevel 1850 guide?
              </p>
              <div className="w-full max-w-[437px] lg:mt-6 mt-2">
                <input
                  type="text"
                  placeholder="First Name (optional)"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  className="w-full lg:h-auto h-10 py-2 px-4 bg-[#EFE8DF] border-[#959595] border rounded-[5px] outline-none text-base text-[#0F0F0F] font-inter lg:mb-4 mb-2"
                />

                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full lg:h-auto h-10 py-2 px-4 bg-[#EFE8DF] border-[#959595] border rounded-[5px] outline-none text-base text-[#0F0F0F] font-inter"
                />

                <button
                  className="px-4 py-2 bg-[#420AF2] w-full mt-2 text-white font-medium rounded-md focus:outline-none transition"
                  onClick={handleReceiveNow}
                >
                  RECEIVE NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
