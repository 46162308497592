import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IconSvg } from '../ui/IconSvg';
import { Button } from '../ui/button/Button';
// import { Button, IconSvg } from 'src/common/ui-components';

interface IProps {
  children: JSX.Element;
  isOpen: boolean;
  close: () => void;
  isStatic?: boolean;
  loading?: boolean;
  modalWidth?: string;
  className?: string;
  modalBgClassName?: string;
  disable?: boolean;
  onSubmit?: (value?: any) => void;
  submitButtonText?: string;
  cancelButtonText?: string;
  buttonsConteinerClassName?: string;
  submitButtonClassName?: string;
  cancelButtonClassName?: string;
  withCrossBtn?: boolean;
  wrapperStyles?: string;
}
export function Modal({
  children,
  isOpen,
  close,
  isStatic,
  loading = false,
  className = '',
  modalBgClassName = '',
  disable = false,
  onSubmit,
  modalWidth = 'w-auto',
  submitButtonText = '',
  cancelButtonText = '',
  buttonsConteinerClassName = '',
  submitButtonClassName = '',
  cancelButtonClassName = '',
  withCrossBtn = false,
  wrapperStyles,
}: IProps) {
  return isOpen ? (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog static as="div" className="relative z-10" onClose={isStatic ? () => null : close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0 translate-y-[-20px] scale-95"
          enterTo="opacity-100 translate-y-0 scale-100"
          leave="ease-in duration-400"
          leaveFrom="opacity-100 translate-y-0 scale-100"
          leaveTo="opacity-0 translate-y-[-20px] scale-95"
        >
          <div
            className={`fixed inset-0 transition-opacity bg-[#00000033] bg-opacity-75 ${modalBgClassName}`}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 translate-y-10 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-400"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-10 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                onClick={(e: any) => e.stopPropagation()}
                className={`relative transform rounded-lg bg-[#EFE8DF] sm:p-6 p-4 text-left shadow-xl transition-all duration-800 ease-in-out opacity-0 animate-fadeIn ${modalWidth} ${wrapperStyles} overflow-y-auto max-h-[90vh] no-scrollbar`}
              >
                {withCrossBtn && (
                  <div className="flex items-center space-x-1 cursor-pointer w-fit" onClick={close}>
                    <IconSvg icon="cross" className="cursor-pointer" />
                    <p className="text-sm font-medium">Close</p>
                  </div>
                )}

                {children}

                {(submitButtonText || cancelButtonText) && (
                  <>
                    <div className="flex items-end justify-end mt-6 space-x-4">
                      {close && cancelButtonText && (
                        <Button
                          label={cancelButtonText}
                          size="small"
                          color="gray"
                          onClick={close}
                          className={cancelButtonClassName}
                        />
                      )}

                      {onSubmit && submitButtonText && (
                        <Button
                          isDisabled={loading || disable}
                          label={submitButtonText}
                          size="small"
                          className={submitButtonClassName ? submitButtonClassName : ''}
                          color="secondary"
                          onClick={onSubmit}
                        />
                      )}
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : (
    <>{null}</>
  );
}
