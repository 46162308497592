import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Button, IconSvg } from 'src/components/common';
import { InputText } from 'src/components/common/form';
import Styles from '../booking.module.scss';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface DropdownMenuProps {
  name?: string;
  onChange: (value: string, name: string) => void;
  defaultValue?: string;
  background?: string;
  placeholder?: string;
  icon?: boolean;
  className?: string;
  onApplyHandler: (payload: Room[]) => void;
}

export interface DropdownMenuOption {
  name: string;
  value: string;
  icon?: React.ReactNode;
}
interface Room {
  adults: number;
  children: { age: string; isValid: boolean }[];
}

const createAgeOptions = () => {
  return Array.from({ length: 17 }, (_, i) => {
    const labelValue = `${i + 1} year old`;
    return { label: labelValue, value: labelValue };
  });
};

export function GuestsMenuCard({
  name,
  className = '',
  defaultValue = '',
  background = '',
  onChange,
  icon = false,
  placeholder = 'Select item',
  onApplyHandler,
}: DropdownMenuProps) {
  const ageOptions = createAgeOptions();

  const [rooms, setRooms] = useState<Room[]>([{ adults: 2, children: [] }]);
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);

  const addRoom = () => {
    if (rooms.length < 6) {
      setRooms([...rooms, { adults: 2, children: [] }]);
    }
  };

  const removeRoom = (index: number) => {
    setRooms(rooms.filter((_, i) => i !== index));
  };

  const updateAdults = (index: number, increment: boolean) => {
    const newRooms = [...rooms];
    newRooms[index].adults = Math.min(
      Math.max(newRooms[index].adults + (increment ? 1 : -1), 1),
      4,
    );
    setRooms(newRooms);
  };

  const addChild = (index: number) => {
    const newRooms = [...rooms];
    if (newRooms[index].children.length < 4) {
      newRooms[index].children.push({ age: '', isValid: true });
      setRooms(newRooms);
    }
  };

  const removeChild = (roomIndex: number) => {
    const newRooms = [...rooms];
    newRooms[roomIndex].children.pop();
    setRooms(newRooms);
  };

  const updateChildAge = (roomIndex: number, childIndex: number, age: string) => {
    const newRooms = [...rooms];
    const ageNumber = parseInt(age, 10);
    const isValid = ageNumber >= 1 && ageNumber <= 17;
    newRooms[roomIndex].children[childIndex] = { age, isValid };
    setRooms(newRooms);
    checkAllChildrenAges(newRooms);
  };

  const checkAllChildrenAges = (rooms: Room[]) => {
    const isDisabled = rooms.some(room =>
      room.children.some(child => parseInt(child.age, 10) > 17)
    );
    setIsApplyDisabled(isDisabled);
  };

  const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent the event from propagating to the parent div
  };
  return (
    <div onClick={handleDropdownClick}>
      <Menu as="div" className={'relative inline-block text-left w-full'}>
        <Menu.Button
          className={`${
            icon
              ? 'w-full'
              : `inline-flex w-full justify-center 
        ${
          background ? `bg-[${background}]` : 'bg-white'
        }  text-base font-normal text-[#000000A3] hover:bg-gray-50 focus:outline-none rounded-[5px] flex items-center justify-center`
          }`}
        >
          <div className="flex items-center justify-between lg:h-[60px] h-10 bg-[#EFE8DF] px-2 rounded-[5px] w-full">
            <div className="flex items-center  gap-2">
              <IconSvg icon="userBlack" className="lg:h-8 lg:w-8 h-6 w-6" />
              <p className="font-inter lg:text-lg text-sm lg:leading-[21.6px] leading-[16.8px] font-light text-[#4A4A4A]">
                {placeholder}
              </p>
            </div>
            {/* <IconSvg icon="downArrowHeadCream" /> */}
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`z-50 absolute ${'right-0'}  mt-2 origin-top-right rounded-md bg-[#EFE8DF] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${className}`}
            onKeyDown={(e: any) => {
              if (e.key === 'Tab') {
                e.preventDefault();
              }
            }}
          >
            <div
              // onClick={() => handleItemClick(item.value, item.name)}
              className={classNames(' pb-5')}
            >
              <div className={`${Styles['guests-menu-card']}`}>
                {rooms.map((room, roomIndex) => (
                  <>
                    <div className={classNames('pt-5 px-3')} key={roomIndex}>
                      <div className="flex justify-between items-center">
                        <h4 className="font-inter text-lg font-bold leading-[21.8px]">
                          Room {roomIndex + 1}
                        </h4>
                        <p
                          className="font-inter text-sm font-medium leading-[16.8px] text-[#9B2626] cursor-pointer"
                          onClick={() => removeRoom(roomIndex)}
                        >
                          Delete room
                        </p>
                      </div>
                      <div className="flex justify-between items-center my-4">
                        <h6 className="font-inter text-lg font-light leading-[21.6px]">
                          Adults
                        </h6>
                        <div className="px-2 py-1.5 flex justify-between items-center border rounded-[5px] w-[115px] border-[#959595]">
                          <div
                            className="h-[18px] w-[18px] bg-[#E2E2E2] border-[#959595] border rounded-[5px] flex justify-center items-center cursor-pointer"
                            onClick={() => updateAdults(roomIndex, false)}
                          >
                            <IconSvg icon="minusOrange" />
                          </div>
                          <p className="font-inter text-lg font-light leading-[21.6px]">
                            {room.adults}
                          </p>
                          <div
                            className="h-[18px] w-[18px] bg-[#E2E2E2] border-[#959595] border rounded-[5px] flex justify-center items-center cursor-pointer"
                            onClick={() => updateAdults(roomIndex, true)}
                          >
                            <IconSvg icon="plusOrange" />
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <h6 className="font-inter text-lg font-light leading-[21.6px]">
                          Children
                        </h6>
                        <div className="px-2 py-1.5 flex justify-between items-center border rounded-[5px] w-[115px] border-[#959595]">
                          <div
                            className="h-[18px] w-[18px] bg-[#E2E2E2] border-[#959595] border rounded-[5px] flex justify-center items-center cursor-pointer"
                            onClick={() => removeChild(roomIndex)}
                          >
                            <IconSvg icon="minusOrange" />
                          </div>
                          <p className="font-inter text-lg font-light leading-[21.6px]">
                            {room.children.length}
                          </p>
                          <div
                            className="h-[18px] w-[18px] bg-[#E2E2E2] border-[#959595] border rounded-[5px] flex justify-center items-center cursor-pointer"
                            onClick={() => addChild(roomIndex)}
                          >
                            <IconSvg icon="plusOrange" />
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-4 mt-2">
                        {room.children.map((child, childIndex) => (
                          <div className="col-span-6 " key={childIndex}>
                            <InputText
                              id={`children-${roomIndex}-${childIndex}`}
                              name={`children-${roomIndex}-${childIndex}`}
                              label={'Age'}
                              type="number"
                              placeholder={''}
                              value={child.age}
                              onChange={({ name, value }: { name: string; value: string }) =>
                                updateChildAge(roomIndex, childIndex, value)
                              }
                              isDisabled={false}
                              min={1}
                              max={17}
                              isError={!child.isValid}
                              errorMessage={'Age must be between 1 and 17'}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="border-b w-full border-[#959595] mt-6"></div>
                  </>
                ))}
              </div>
              <div className="px-3">
                {rooms.length < 6 && (
                  <div className="flex items-center gap-2 py-6 cursor-pointer" onClick={addRoom}>
                    <div className="h-5 w-5 rounded-full border border-[#C56B48] flex justify-center items-center">
                      <IconSvg icon="plusOrange" />
                    </div>
                    <p className="font-inter text-sm font-medium leading-[16.8px] text-[#C56B48]">
                      Add more
                    </p>
                  </div>
                )}
                <div>
                  <p className="font-inter text-base font-light leading-[19.2px]">
                    To find you a place to stay that fits your entire group along with correct
                    prices, we need to know how old your children will be at check-out
                  </p>
                </div>
                <Menu.Item>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    label={
                      <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                        Apply
                      </p>
                    }
                    isDisabled={isApplyDisabled}
                    className="w-full mt-4"
                    onClick={() => !isApplyDisabled && onApplyHandler(rooms)}
                  />
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
