import React, { ReactNode, useState } from 'react';
const Slider = React.lazy(() => import('react-slick') as Promise<{ default: any }>);

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icons } from 'src/types/icons';
import { IconSvg } from '../common';

interface OuerMembersSay {
  img: Icons;
  name: string;
  star: boolean;
  message: ReactNode;
}
export function OurMemberSayMobile() {
  const [sliderRef, setSliderRef] = useState<any | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const sliderData: OuerMembersSay[] = [
    {
      img: 'dummyUserPicSmallPng',
      name: '- Lolita Piotrovskaya',
      message: (
        <div className="w-full font-light ">
          <div className="leading-6 font-inter text-[20px]">
            <IconSvg
              icon="horizontalDoubleHeadLongArrow"
              className="lg:w-[145px] w-[92px] inline-block"
            />
            <span className="pl-2">
              Here&apos;s a secret!{' '}
              {/* <span className="bg-[#C56B48] text-[#EFE8DF] font-inter rounded-[5px] font-bold px-2 inline-block"> */}
              Public hotel rates are old news -{/* </span> */}
            </span>
            &nbsp;as a luxury traveller, I&apos;m saving thousands of my travel dollars with
            <span className="bg-[#C56B48] text-[#EFE8DF] rounded-[5px] font-inter font-bold px-2 inline-block">
              XPLORIUS hidden hotel deals.
            </span>{' '}
            My first booking alone saved me 10 times more than what I paid for my annual membership.
            XPLORIUS is a game-changer for anyone who loves to travel!{' '}
          </div>
        </div>
      ),
      star: false,
    },
    {
      img: 'rohAllahMobilePng',
      name: '- Rohallah (London, UK)',
      message: (
        <p>
          <IconSvg
            icon="horizontalDoubleHeadLongArrow"
            className="lg:w-[145px] w-[92px] inline-block"
          />
          <span className="pl-2">
            <span className="bg-[#C56B48] text-[#EFE8DF] rounded font-bold font-inter px-1 inline-block">
              I&apos;ve saved 522 USD
            </span>
            <br />
            <span className="bg-[#C56B48] text-[#EFE8DF] rounded font-bold font-inter px-1 inline-block">
              on my first booking
            </span>
          </span>{' '}
          with XPLORIUS. With such savings right out of the gate, I{`'`}m already convinced that the
          annual subscription fee was a wise investment.
        </p>
      ),
      star: true,
    },
    {
      img: 'lukaMobilePng',
      message: (
        <p>
          <IconSvg
            icon="horizontalDoubleHeadLongArrow"
            className="lg:w-[145px] w-[92px] inline-block"
          />
          <span className="pl-2">
            The value is undeniable. What truly sets XPLORIUS apart is the <br />
            <span className="bg-[#C56B48] text-[#EFE8DF] rounded font-inter font-bold px-2 inline-block">
              exceptional customer service,
            </span>{' '}
            always willing to go the extra mile for the members.
          </span>
        </p>
      ),
      name: '- Luka & Maria (Dubai, UAE)',
      star: true,
    },
  ];

  const settingsMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    // centerHeight: '227px',
    centerWidth: 'w-full',
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),

    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };
  return (
    <>
      <div className="mt-[32px] px-[11px] ">
        <h3 className="uppercase font-inter text-[#0F0F0F] font-medium text-[24px] leading-[28.32px]">
          OUR MEMBERS SAY
        </h3>

      </div>
      <div className="slick-slider-container-member-say-mobile mt-5 relative pr-[11px]">
                  <React.Suspense fallback={<div>Loading...</div>}>
        
        <Slider
          {...settingsMobileScreen}
          ref={(slider:any) => setSliderRef(slider)}
          className="flex !gap-2.5"
        >
          {sliderData.map((item, i) => (
            <div className="" key={i}>
              <div className='ml-[11px]'>
                <IconSvg icon={item.img} className="w-full bg-[#EFE8DF]" />
                <p className="text-xl leading-6 font-light font-inter mt-[30px]">{item.message}</p>
                <div className="flex flex-col justify-center items-center">
                  <div className="flex flex-col items-center">
                    <p className={`font-inter italic font-normal mt-[15px]  text-base text-center`}>
                      {item.name}
                    </p>
                    {item.star && <IconSvg className="mt-2 text-center" icon="stars" />}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        </React.Suspense>
        <div
          className={` flex items-center justify-between pr-5 absolute  ${
            currentIndex == 0 ? 'bottom-[2px]' : 'bottom-[60px]'
          }  w-full pl-[11px]`}
        >
          {settingsMobileScreen.prevArrow}
          {settingsMobileScreen.nextArrow}
        </div>
      </div>
    </>
  );
}
